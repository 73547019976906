:root {
  --main-color: #ff9c00;
  --main-color-hover: #fdb646;
  --main-blue-color: #1F5CC4;
  --main-blue-color-hover: #2789f8;
  --main-background-color: #051E29;
  --main-background-color-light: #fafbfd;
  --main-color-navyLight: #384f59;
  --main-color-green: #57df5a;
  --main-color-green-hover: #68bf6a;
  --main-color-red: #ee2525;
  --secondary-color-yellow: #fdc80b;
}
.ReactModal__Overlay{
  background-color: #384f59de !important;
}
.button {
  outline: none;
  border: none;
  cursor: pointer;
  height: 35px;
}
.button-primary{
  border-radius: 5px;
  color: white;
  font-weight: bold;
  background-color: var(--main-background-color);
  padding: 0 10px;
}
.button-primary-disabled{
  border-radius: 5px;
  color: white;
  font-weight: bold;
  background-color: var(--main-color-navyLight) !important;
  cursor: not-allowed !important;
  padding: 0 10px;
}
.button-primary:hover{
  background-color: var(--main-color-navyLight);
}
.button-regular:hover{ 
  color: white
}
.button-regular{
  border-radius: 5px;
  color: var(--main-background-color-light);
  font-weight: bold;
  background-color: var(--main-background-color);
  padding: 0 10px;
}
.button-selected{
  border-radius: 5px;
  background: #50588b;
  color: white;
  font-weight: bold;
  padding: 0 10px;
  border: 1px solid var(--main-color)
}
.button-selected:hover{
  background: #5d66a1;
}
.button-edit{
  border-radius: 5px;
  background: white;
  color: black;
  font-weight: bold;
  padding: 0 10px;
  border: 1px solid green
}
.button-edit:hover{
  opacity: 0.8;
}
.button-secondary{
  border-radius: 5px;
  background: white;
  color: black;
  font-weight: bold;
  padding: 0 10px;
  border: 1px solid var(--main-blue-color)
}
.button-edit:hover{
  opacity: 0.8;
}
.body {
  display: flex;
  width: 98%;
}
.text-blue{
    color: var(--main-blue-color);
    cursor: pointer;
}
.text-black{
    color: black !important;
}
.text-white{
    color: white;
}
.text-red{
    color: rgb(236, 41, 41);
    cursor: pointer;
}
.text-green{
    color: var(--main-color-green);
    cursor: pointer;
}
.text-grey{
  color: grey;
}
.text-light-grey{
  color: #384f59;
}
.d-block{
    display: block
}
.d-flex{
    display: flex
}
.flex-col{
  flex-direction: column;
}
.align-center{
  align-items: center;
}
.mb-20{
    margin-bottom: 20px;
}
.mb-10{
    margin-bottom: 10px;
}
.mb-5{
    margin-bottom: 5px;
}
.mb-0{
    margin-bottom: 0;
}
.ml-10{
  margin-left: 10px
}
.ml-5{
  margin-left: 5px
}
.ml-20{
  margin-left: 20px
}
.mt-0{
  margin-top: 0px;
}
.mt-5{
  margin-top: 5px;
}
.mt-10{
  margin-top: 10px;
}
.mt-15{
  margin-top: 15px;
}
.mt-20{
  margin-top: 20px;
}
.mr-5{
  margin-right: 5px;
}
.text-center{
    text-align: center;
}
.text-right{
    text-align: right;
}
.m-0{
  margin: 0 !important;
}
.m-auto{
  margin: 0 auto
}
.p-0-5{
  padding: 0 5px;
}
.dir-col{
  flex-direction: column
}
.bt-grey{
  display: block;
  border-top: 1px solid grey;
}
.w-fit{
    width: fit-content
}
.fw-500{
  font-weight: 500;
}
.fw-400{
  font-weight: 400;
}
.fs-10{
  font-size: 10px !important;
}
.fs-12{
  font-size: 12px !important;
}
.w-100pc{
  width: 100% ;
}
.w-98pc{
  width: 98% ;
}
.bold{
  font-weight: bold;
}
.float-right{
  float: right;
}
.hover-green:hover{
  color: green;
  cursor: pointer;
}
.hover-red:hover{
  cursor: pointer;
  color: red
}
.justify-around{
  justify-content: space-around;
}
.justify-between{
  justify-content: space-between;
}
.justify-center{
  justify-content: center;
}
.flex-wrap{
  flex-wrap: wrap;
}
.image-product-s{
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-right: 10px;
}
.image-product-n{
  width: 100%;
  height: 150px;
  object-fit: contain;
}
.pos-relative{
  position: relative;
}
.cursor-pointer{
  cursor: pointer
}
.pos-absolute{
  position: absolute;
}
.flex-1{
  flex: 1;
}
.flex-2{
  flex: 2;
}
.flex-3{
  flex: 3;
}
.flex-4{
  flex: 4;
}
.flex-5{
  flex: 5;
}
.flex-6{
  flex: 6;
}
.text-grey{
  color: grey
}
.text-dark-grey{
  color: rgb(103, 103, 103)
}
.text-red{
  color: red
}
.text-blue{
  color: blue
}
.text-green{
  color: green
}
.text-orange{
  color: orange
}
.text-orange-dark{
  color: rgb(255, 136, 0)
}
.pb-10{
  padding-bottom: 10px;
}
.border-bottom-grey{
  border-bottom: 1px solid grey;
}
/* loading */
@keyframes ldio-up3jaymtcc {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}
.ldio-up3jaymtcc > div > div {
  transform-origin: 100px 100px;
  animation: ldio-up3jaymtcc 3.0303030303030303s linear infinite;
  opacity: 0.8
}
.ldio-up3jaymtcc > div > div > div {
  position: absolute;
  left: 30px;
  top: 30px;
  width: 70px;
  height: 70px;
  border-radius: 70px 0 0 0;
  transform-origin: 100px 100px
}.ldio-up3jaymtcc > div div:nth-child(1) {
  animation-duration: 0.7575757575757576s
}
.ldio-up3jaymtcc > div div:nth-child(1) > div {
  background: #e15b64;
  transform: rotate(0deg);
}.ldio-up3jaymtcc > div div:nth-child(2) {
  animation-duration: 1.0101010101010102s
}
.ldio-up3jaymtcc > div div:nth-child(2) > div {
  background: #f47e60;
  transform: rotate(0deg);
}.ldio-up3jaymtcc > div div:nth-child(3) {
  animation-duration: 1.5151515151515151s
}
.ldio-up3jaymtcc > div div:nth-child(3) > div {
  background: #f8b26a;
  transform: rotate(0deg);
}.ldio-up3jaymtcc > div div:nth-child(4) {
  animation-duration: 3.0303030303030303s
}
.ldio-up3jaymtcc > div div:nth-child(4) > div {
  background: #abbd81;
  transform: rotate(0deg);
}
.loadingio-spinner-wedges-w3791ts7qvh {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
  margin: 0 auto;
}
.ldio-up3jaymtcc {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-up3jaymtcc div { box-sizing: content-box; }
/* loader */

@keyframes ldio-j6ozdin8wa9 {
  0% { opacity: 1 }
  100% { opacity: 0 }
}
.ldio-j6ozdin8wa9 div {
  left: 47px;
  top: 24px;
  position: absolute;
  animation: ldio-j6ozdin8wa9 linear 1s infinite;
  background: #fe718d;
  width: 6px;
  height: 12px;
  border-radius: 3px / 6px;
  transform-origin: 3px 26px;
}.ldio-j6ozdin8wa9 div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
  background: #fe718d;
}.ldio-j6ozdin8wa9 div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
  background: #fe718d;
}.ldio-j6ozdin8wa9 div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
  background: #fe718d;
}.ldio-j6ozdin8wa9 div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
  background: #fe718d;
}.ldio-j6ozdin8wa9 div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
  background: #fe718d;
}.ldio-j6ozdin8wa9 div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
  background: #fe718d;
}.ldio-j6ozdin8wa9 div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
  background: #fe718d;
}.ldio-j6ozdin8wa9 div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
  background: #fe718d;
}.ldio-j6ozdin8wa9 div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
  background: #fe718d;
}.ldio-j6ozdin8wa9 div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
  background: #fe718d;
}.ldio-j6ozdin8wa9 div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
  background: #fe718d;
}.ldio-j6ozdin8wa9 div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #fe718d;
}
.loadingio-spinner-spinner-nmqu9jrtsd {
  width: 18px;
  height: 18px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
  margin: 0 auto;
}
.ldio-j6ozdin8wa9 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.18);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-j6ozdin8wa9 div { box-sizing: content-box; }


@keyframes ldio-pjthvymcn1n {
  0% { opacity: 1 }
  100% { opacity: 0 }
}
.ldio-pjthvymcn1n div {
  left: 47px;
  top: 24px;
  position: absolute;
  animation: ldio-pjthvymcn1n linear 1s infinite;
  background: #ffffff;
  width: 6px;
  height: 12px;
  border-radius: 3px / 6px;
  transform-origin: 3px 26px;
}.ldio-pjthvymcn1n div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
  background: #ffffff;
}.ldio-pjthvymcn1n div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
  background: #ffffff;
}.ldio-pjthvymcn1n div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
  background: #ffffff;
}.ldio-pjthvymcn1n div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
  background: #ffffff;
}.ldio-pjthvymcn1n div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
  background: #ffffff;
}.ldio-pjthvymcn1n div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
  background: #ffffff;
}.ldio-pjthvymcn1n div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
  background: #ffffff;
}.ldio-pjthvymcn1n div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
  background: #ffffff;
}.ldio-pjthvymcn1n div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
  background: #ffffff;
}.ldio-pjthvymcn1n div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
  background: #ffffff;
}.ldio-pjthvymcn1n div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
  background: #ffffff;
}.ldio-pjthvymcn1n div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #ffffff;
}
.loadingio-spinner-spinner-nrmdiyh6l {
  width: 18px;
  height: 18px;
  display: inline-block;
  overflow: hidden;
  position: absolute;
  top: 8px;
}
.ldio-pjthvymcn1n {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.18);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-pjthvymcn1n div { box-sizing: content-box; }