@media all and (min-width: 700px) {
    .card-item-product{
        display: none !important;
    }
    .icon-side-nav{
        display: none !important;
    }
}
@media all and (max-width: 1000px) {
    /* .navbar{
        display: none
    } */
}

@media all and (max-width: 670px) {
    #user-detail-modal{
        left: 20px !important;
        right: 20px !important;
    }
    #add-product-modal{
        left: 20px !important;
        right: 20px !important;
    }
    #add-information-modal{
        width: 300px !important;
        min-width: 0 !important;
    }
    #barcode-modal{
        width: 300px !important;
        min-width: 0 !important;
    }
    .navbar{
        display: none
    }
    .container{
        padding-left: 2vw !important;
        padding-right: 2vw;
        padding-top: 2vh !important;
    }
    .wrapper-logout{
        right: 2vw !important;
        top: 2vh !important;
    }
    .header{
        margin-top: 5vh;
    }
    .wrapper-input-search{
        width: 100% !important;
    }
    .body_user{
        flex-direction: column;
    }
    .content {
        width: 100% !important;
    }
    .fitur-user {
        padding: 0 !important;
        width: 100% !important;
        margin-bottom: 10px;
    }
    .user-activity{
        min-height: 250px;
        max-height: 500px;
        overflow-y: auto;
    }
    .fa-times-circle{
        right: 10px !important;
    }
    .wrapper-button{
        flex-direction: column;
    }
    .button-update-user{
        width: 100% !important;
        margin-bottom: 10px;
    }
    .button-delete-user{
        width: 100% !important;
    }
    .wrapper-info-user-detail .input-name {
        width: 200px !important;
    }
    .wrapper-info-user-detail .input-address {
        width: 200px !important;
    }
    .wrapper-info-user-detail {
        padding: 0 3px;
    }
    .wrapper-product-information{
        padding: 15px !important;
    }
    .wrapper-sales-information{
        padding: 15px !important;
    }
    .wrapper-input-variant{
        flex-direction: column;
        align-items: flex-start !important;
        margin-right: 0 !important;
        margin-bottom: 10px ;
    }
    .wrapper-input-sale{
        flex-direction: column;
        align-items: flex-start !important;
        margin-right: 0 !important;
        margin-top: 10px !important;
    }
    .wrapper-barcode{
        width: 100% !important;
        overflow-x: auto !important;
    }
    .user-access-edit{
        width: 200px !important;
    }
    .header-product {
        flex-direction: column;
        height: 75px !important;
    }
    .header-wrapper-button{
        margin-top:10px;
    }
    .header-wrapper-button-product{
        justify-content: flex-end;
    }
    .popup-filter{
        left: -50px !important;
    }
    .popup-filter-marketplace{
        
    }
    .creatable-input-add-product{
        width: 250px !important;
    }
    .section-input-variant{
        flex-direction: column;
    }
    .side-bar{
        width: 50% !important;
    }
    .card-item{
        width: 150px !important;
    }
    .button-discount{
        margin-left: 0 !important;
    }
    .header-information{
        height: 0 !important;
    }
    .wrapper-marketplace-content-header{
        flex-direction: column;
        align-items: flex-start !important;
        margin-bottom: 10px;
    }
    .header-marketplace-wrapper-button button{
        margin-left: 0 !important;
    }
    .header-marketplace-wrapper-button {
        width: 100%;
        justify-content: space-between;
    }
    .wrapper-marketplace{
        max-height: 500px !important;
    }
    .section-variant{
        flex-direction: column;
    }
    .wrapper-info-sku{
        width: 100% !important;
        margin-bottom: 20px;
    }
    .wrapper-variant{
        width: 100% !important;
    }
    .wrapper-status__action span{
        width: 100% !important;
        text-align: center;
    }
    .thead-product{
        display: none;
    }
    .tr-product{
        display: none !important;
    }
    .card-item-product{
        height: fit-content !important;
        width: 100% !important;
    }
    .item-category {
        font-size: 14px;
    }
    .item-size{
        font-size: 12px;
    }
    .table-product{
        background-color: transparent !important;
        box-shadow: 0 0px 0px 0px hsla(0,0%,68.6%,.5), 0 6px 6px hsla(0,0%,68.6%,.3) !important;
    }
    .button-mobile{
        height: 30px !important;
    }
    .wrapper__icon{
        justify-content: flex-start !important;
    }
    .logo{
        margin-bottom: 20px !important;
    }
    .wrapper-card{
        padding: 10px;
    }
    .card--report{
        width: 100% !important;
        margin-right: 0 !important;
    }
    .card--report--s{
        width: 100% !important;
        margin-right: 0 !important;
    }
    .breadcump--report{
        margin-top: 0 !important;
    }
    .button--date{
        font-size: 12px;
    }
    .wrapper--graph-sales{
        flex-direction: column;
    }
    .card--graph{
        width: 100% !important;
        margin: 0 !important;
        margin-bottom: 10px !important;
        height: 230px !important;
    }
    .custom-span__mobile{
        display: block;
    }
    .wrapper-loading-sales__home{
        width: 100% !important;
        overflow: hidden; 
        height: 200px
    }
    .wrapper-loading-sales__home__right{
        margin-right: 0px !important;
    }
    .modal-detail-transaction{
        width: 100% !important;
    }
    .modal-detail-transaction .wrapper-title-header{
        flex-direction: column;
    }
    .button-delete-transaction{
        margin-left: 0 !important;
        margin-top: 5px;
    }
    .btn-export-excel{
        margin: 0
    }
    .btn-nav-product-list{
        margin-left: 0 !important;
    }
    .wrapper-output-list{
        flex-direction: column-reverse;
        margin-top: 20px;
    }
    .wrapper-resume{
        margin-right: 0 !important;
    }
}