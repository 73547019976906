.body-ecommerce{
}
.wrapper-jumbotron{
    box-shadow: 0 1px 1px 1px hsl(0deg 0% 69% / 50%), 0 6px 6px hsl(0deg 0% 69% / 30%);
    padding: 10px;
    margin-bottom: 30px;
}
.btn-add-image{
    cursor: pointer;
    font-weight: bold;
    color: blue;
}
.box-upload-image .fa-trash{
    position: absolute;
    color: red;
    top: 2px;
    right: 0;
    cursor: pointer;
    font-size: 12px;
    height: 12px;
    width: 12px;
}
.wrapper-transaction{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    background-color: #f7f7f7;
    padding: 20px;
}
.transaction-list{
    display: flex;
    box-shadow: 0px 0 4px #e9e8e8;
    border-radius: 3px;
    padding: 10px 5px;
    font-size: 14px;
    font-weight: 500;
    color: rgb(69, 69, 69);
    cursor: default;
    background-color: white;
    margin: 2px 0;
}
.transaction-list:hover{
    box-shadow: 0px 0 5px 1px #a4a4a4;
}
.transaction-list-head{
    padding: 3px 5px;
    display: flex;
    color: rgb(110, 121, 125);
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 10px;
}
.body-ecommerce h4{
    color: #5c5c5c
}
.detail-order-header{
    border-bottom: 1px dashed grey;
    padding-bottom: 20px;
}
.divider{
    border-left: 1px solid rgba(0,0,0,0.1);
    margin: 0 10px;
}
.detail-order-header .divider{
    height: 30px;
}
.custom-divider{
    height: 0.1875rem;
    width: 100%;
    background-position-x: -1.875rem;
    background-size: 7.25rem 0.1875rem;
    background-image: repeating-linear-gradient(45deg,#6fa6d6,#6fa6d6 33px,transparent 0,transparent 41px,#f18d9b 0,#f18d9b 74px,transparent 0,transparent 82px);
}
.detail-order-product-list img{
    width: 90px;
    height: 90px;
}
.detail-order-product-list{
    margin-top:20px;
    background-color: rgb(243, 242, 242);
    display: flex;
    justify-content: space-between;
    padding: 10px;
}
.input-resi{
    height: 35px;
    padding: 3px 5px;
    border-radius: 3px;
    width: 300px;   
}
.wrapper-input-resi button{
    height: 35px;
    cursor: pointer;
}