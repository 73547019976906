.App {
  min-height: 100vh;
  background-color: var(--main-background-color-ligh);
  position: relative;
  display: flex;
  max-width: 100vw;
  overflow: hidden;
  transition: all .5s ease;
}
.container {
  padding-top: 5vh;
  width: 100vw;
  padding-left: 10vw;
}